// custom typefaces
//import "typeface-montserrat"
//import "typeface-merriweather"

// normalize CSS across browsers
//import "./src/css/normalize.css"
import "@fontsource/raleway"
import 'bootstrap/dist/css/bootstrap.min.css';

// custom CSS styles
import "./src/css/style.css"
/*import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Le3reYcAAAAAJNE_2exBuax54odN_ag9_VAeqrQ">
            {element}
        </GoogleReCaptchaProvider>
    )
} */

export const onServiceWorkerUpdateReady = () => window.location.reload(true)
